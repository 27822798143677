import React, { useContext, useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import "./LandingPage.scss";
import { calculateCountdown, landingData, logoLottieOptions } from "./constant";
import fullLogo from "../../assets/fullLogo.json";
import Button from "components/Button";
import LM from "../../assets/svg/Content";
import SubscribeService from "services/subsribe.service";
import CircleLoader from "components/CircleLoader";
import { validateEmail } from "./constant/index";
import ToasterService from "utils/toaster.util";
import AuthPopup from "components/popups/auth/AuthPopup";
import { AuthContext } from "components/context/AuthContext";
import axios from "axios";
import BgAnimation from "components/Landing/BgAnimation";
import Header from "components/Header/Header";
import { coins } from "pages/ico";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
const LandingPage = () => {
  const [countdown, setCountdown] = React.useState(calculateCountdown());
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    linkedInURL: "",
    twitterURL: "",
    instagramURL: "",
    country: "",
    message: "",
    termsAndConditions: false,
  });
  let validEmail = validateEmail(formData.email);
  const { auth, setAuth, setAuthType } = useContext(AuthContext);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [hoveredCoin, setHoveredCoin] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const scrollContainerRef = useRef(null);
  const coinRefs = useRef([]);
  const handleArrowClick = (direction) => {
    setSelectedCoin((prev) => {
      const newIndex =
        direction === "left"
          ? prev === 0
            ? coins.length - 1
            : prev - 1
          : prev === coins.length - 1
          ? 0
          : prev + 1;

      // Scroll the new active coin into view
      setTimeout(() => {
        coinRefs.current[newIndex]?.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }, 100);

      return newIndex;
    });
  };

  async function getAuthStatus(token) {
    // setAuth((p) => ({ ...p, loading: true }));

    // try {
    //   const res = await axios.get(
    //     process.env.REACT_APP_EVERYONE_BACKEND_URL + "/auth/status",
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );

    //   if (!res.data.authenticated)
    //     setAuthType({ authType: "register", open: true });

    //   if (res.data.authenticated && res.data.user) {
    //     setAuth({
    //       ...auth,
    //       isLoggedIn: true,
    //       user: res.data.user,
    //       useCase: res.data.user.useCase,
    //       dateOfBirth: res.data.user.dateOfBirth,
    //       username: res.data.user.username,
    //       phone: res.data.user.phone?.number,
    //       countryCode: res.data.user.phone?.code,
    //     });

    //     if (!res.data.user.useCase)
    //       setAuthType({ authType: "dob", open: true });
    //   }
    // } catch (error) {
    //   console.error(error);
    //   // if (!error.response) console.error("Network error, please try again");
    //   // else console.error("Something went wrong, please try again");
    // } finally {
    //   setAuth((p) => ({ ...p, loading: false }));
    // }
  }

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      setAuth({ isLoggedIn: false });
      setAuthType({ authType: "register", open: true });
    } else {
      getAuthStatus(token);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open) {
      setIsChecked(false);
    }
  }, [open]);

  const handleCheckboxChange = () => {
    if (!formData.email || validEmail) {
      ToasterService.showError("Enter your valid Email");
      setIsChecked(false);
    } else {
      setOpen(true);
      setIsChecked(true);
    }
  };

  useEffect(() => {
    setCountdown(calculateCountdown());
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // const handleScroll = (event) => {
  //   const backgroundParent = document.querySelector(".backgroundParent");
  //   if (
  //     event.key === "ArrowUp" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("up-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: -700, behavior: "smooth" });
  //   } else if (
  //     event.key === "ArrowDown" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("down-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: 700, behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    window.addEventListener("keydown", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleScroll);
    };
  }, []);

  const SubscribeAsAmbassador = () => {
    SubscribeService.subscribe({ email: formData.email }, setLoading, setOpen);
  };

  const [scrollY, setScrollY] = useState(0);
  const backgroundParentRef = useRef(null);

  const handleScroll = () => {
    if (backgroundParentRef.current) {
      setScrollY(backgroundParentRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const backgroundParent = backgroundParentRef.current;
    if (backgroundParent) {
      backgroundParent.addEventListener("scroll", handleScroll);

      return () => {
        backgroundParent.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * landingData.length);
    setCurrentIndex(randomIndex);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <BgAnimation />
      <div className="backgroundParent" ref={backgroundParentRef}>
        <div className="mainLanding">
          <div className="upperPortion">
            {loading && <CircleLoader />}
            <div
              className={`upper__content ${scrollY > 400 ? "fade-out" : ""}`}
            >
              <div style={{ width: "100%" }}>
                <Header />
              </div>
              <div className="lottie-animation sm:hidden">
                <Lottie options={logoLottieOptions(fullLogo)} />
              </div>
              <div className="h-[80vh] flex justify-center items-center flex-col">

                <div className="text__parent">
                  <h1 className="heading px-2 sm:px-auto">
                    Free Public Channels Visible to the World on One Global Stream
                  </h1>
                  <p className="info px-2 sm:px-auto">
                    Join the Beta to be the first to receive a early channel
                    number, exclusive access, priority support, and special
                    recognition as an early adopter. All free for life.
                  </p>
                </div>

                <div className="form px-2 sm:px-auto mt-4">
                  <div className="input_parent">
                    <input
                      placeholder="Enter your Email"
                      type="text"
                      className="text__input !pl-4 sm:pl-auto"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                    />
                    <Button
                      onClick={SubscribeAsAmbassador}
                      disabled={!formData.email || validEmail}
                      className="!w-[120px] sm:w-auto !py-[12px] sm:p-auto !px-[20px]"
                    >
                      Join Beta
                    </Button>
                  </div>
                  {/* <div className="checkbox__parent">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                <div className="checkbox__text">
                  I want to be an Ambassador. <span>Learn More</span>
                </div>
              </div> */}
                </div>
              </div>

              {/* <div className="border-white border-2 w-[200px] overflow-y-scroll"> */}
              {/* <div
                className="flex gap-4 overflow-x-auto max-w-full px-4 md:overflow-visible scrollbar-hide p-[5px] sm:p-auto"
                ref={scrollContainerRef}
              >
                {coins.map((coin, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedCoin(index)}
                    onMouseEnter={() => setHoveredCoin(index)}
                    onMouseLeave={() => setHoveredCoin(null)}
                    className={`flex-shrink-0 relative transition-transform duration-300 w-16 h-16 cursor-pointer ${
                      selectedCoin === index ? "scale-110" : "scale-100"
                    }`}
                    ref={(el) => (coinRefs.current[index] = el)}
                  >
                    <img
                      src={coin.image}
                      alt={coin.name}
                      className={`w-full h-full p-2 rounded-full ${
                        selectedCoin === index
                          ? "border-4 border-blue-600"
                          : "border-2 border-white"
                      }`}
                    />
                    {hoveredCoin === index && (
                      <div className="absolute -bottom-6 text-sm text-gray-300 w-full text-center">
                        {coin.name}
                      </div>
                    )}
                  </div>
                ))}
              </div> */}

              {/* <main className="flex justify-center items-center max-w-[850px] w-full max-h-[850px]">
                <div className="w-11/12 lg:h-[calc(50vh-100px)] md:h-[30%] md:max-w-[1350px]  md:w-11/12 lg:w-11/12 bg-[#272d3fb3] p-6 rounded-[18px] shadow-lg border-2 border-[#7F8EBA] relative">
                  <button
                    onClick={() => handleArrowClick("left")}
                    className="absolute top-1/2 flex items-center justify-center text-white -left-4 sm:-left-8 -translate-y-1/2 p-1 sm:p-2 bg-[#272D3F] hover:bg-gray-500 rounded-full border-2 border-[#7F8EBA] w-[45px] sm:w-[63px] h-[40px]"
                  >
                    <GoArrowLeft fontSize="26px text-white" />
                  </button>
                  <div className="text-start flex flex-col gap-[17px] px-8 py-6">
                    <LM />
                    <h2 className="text-white text-[40px] sm:text-[63.66px] font-bold mb-2 font-[redhatdisplay-b]">
                      {coins[selectedCoin].name}
                    </h2>
                  </div>
                  <button
                    onClick={() => handleArrowClick("right")}
                    className="absolute flex items-center justify-center top-1/2 -right-4  sm:-right-8 text-white -translate-y-1/2 p-2 hover:bg-gray-500 rounded-full bg-[#272D3F] border-2 border-[#7F8EBA] w-[45px] sm:w-[63px] h-[40px]"
                  >
                    <GoArrowRight fontSize="26px text-white" />
                  </button>
                </div>
              </main> */}
              {/* <div className="timer">
                <div className="days">
                  <div className="count">{countdown.days}</div>
                  <div className="count_name">Days</div>
                </div>
                <div className="colon">:</div>
                <div className="days">
                  <div className="count">{countdown.hours}</div>
                  <div className="count_name">Hours</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.minutes}</div>
                  <div className="count_name">Minutes</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.seconds}</div>
                  <div className="count_name">Seconds</div>
                </div>
              </div> */}
            </div>

            <div
              // className="bottom__content"
              className={`bottom__content ${scrollY > 400 ? "fade-out" : ""}`}
            >
              {/* <div className="bottom__text">
                By joining as a beta user or ambassador, you agree to receive
                communications about our beta release and launch. Early channel
                numbers are not guaranteed and will be distributed on a
                first-come, first-served basis, with some channel numbers
                reserved for existing streams and networks.
              </div>
              <footer className="w-full py-6">
                <div className="container mx-auto px-4">
                  <nav className="flex flex-wrap justify-center mb-6 gap-6 text-white font-[Onest] text-[13.35px]">
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Terms of Service
                    </a>
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="/policies"
                      className="hover:text-gray-200 transition-colors"
                    >
                      Community Guidelines
                    </a>
                  </nav>

                  <div className="flex justify-center gap-6">
                    <a
                      href="#linkedin"
                      className="hover:text-blue-600 transition-colors"
                    >
                      <Linkedin />
                    </a>
                    <a
                      href="#instagram"
                      className="hover:text-pink-600 transition-colors"
                    >
                      <Instagram />
                    </a>
                    <a
                      href="#facebook"
                      className="hover:text-blue-700 transition-colors"
                    >
                      <Facebook />
                    </a>
                    <a
                      href="#twitter"
                      className="hover:text-blue-400 transition-colors"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="#youtube"
                      className="hover:text-red-600 transition-colors"
                    >
                      <Youtube />
                    </a>
                  </div>
                </div>
              </footer>
              <div className="copyRight">
                <MiniLogo />
                <div className="bottom__text">© {new Date().getFullYear()} Everyone World, Inc.</div>
              </div> */}
            </div>
            {/* <div className="actionButtons">
            <div className="cursor up-arrow" onClick={handleScroll}>
              <UpArrow />
            </div>
            <div>
              <MouseIcon />
            </div>
            <div className="cursor down-arrow" onClick={handleScroll}>
              <DownArrow />
            </div>
          </div> */}
            {/* <div className="globe-container">
              <img src={globe} alt="Rotating Globe" className="globe" />
            </div> */}
          </div>

          {/* <AuthPopup /> */}

          {/* <PublicNetwork />

        <VerticalSwiper
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        /> */}
        </div>
        {/* <Modal setOpen={setOpen} open={open}>
        <AmbassadorForm
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
        />
      </Modal> */}
      </div>
    </div>
  );
};

export default LandingPage;

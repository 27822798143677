import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Lottie from "react-lottie";
import { logoLottieOptions } from "pages/LandingPage/constant";
import fullLogo from "../../assets/fullLogo.json";
import Popup from "../../components/popup";

const Header = () => {
    const [showPopup, setShowPopup] = useState(false);
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#001b43c4", 
                color: "#FFFFFF", 
                padding: "10px 20px",
                width: '100%'
            }}
        >
            {/* Left Section */}
            <Box display="flex" alignItems="center">
                {/* Logo */}
                <Box>
                    {/* <Typography variant="h6" sx={{
                        fontWeight: {
                            xs: "400",
                            md: "600"
                        },
                        fontSize: {
                            xs: "20px",
                            md: "24px",
                            lg: "26px",
                        },
                    }}>
                        everyone
                    </Typography>
                    <Typography variant="body2" sx={{
                        fontWeight: "500",
                        fontSize: {
                            xs: "14px",
                            md: "18px",
                        }, color: "#fff", letterSpacing: ".4px"
                    }}>
                        Lorem Ipsum dolor
                    </Typography> */}
                </Box>
            </Box>
            <div className="lottie-animation sm:block hidden">
                <Lottie options={logoLottieOptions(fullLogo)} />
            </div>

            {/* Right Section */}
            {/* <Box textAlign="center">
                <Button
                    onClick={() => setShowPopup(true)}
                    variant="contained"
                    sx={{
                        backgroundColor: "#007BFF",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "5px 15px",
                        width: {
                            xs: "120px",
                            md: "160px"
                        }
                    }}
                >
                    Buy Now!
                </Button>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: {
                            xs: "10px",
                            md: "12px"
                        }, color: "#fff", marginTop: "5px", fontWeight: 500
                    }}
                >
                    Pre-Sale: $0.01 per EVRY
                </Typography>
            </Box> */}
            {showPopup && <Popup setShowPopup={setShowPopup} />}
        </Box>
    );
};

export default Header;
